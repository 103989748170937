import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from '../config/config';

import '../styles/success.scss';

function Success(_, { t }) {
  const service = useSelector((state) => state.service);
  const history = useHistory();

  if (!service.success) {
    history.push('/');
  }
  return (
    <div className="container success-container">
      {console.log(`service.booking.date`, service.booking.date)}
      <h1>{t('SERVICE_DETAILS.SERVICE_BOOKED')}</h1>
      <p className="details m-0 p-0 mt-3 ">{`${service.user.address}, ${service.user.post_code}`}</p>
      <p className="details">
        {moment(service.booking.date).format('D MMM') +
          ', ' +
          config.serviceSlots.find((s) => s.id === service.booking.service_slot_id)?.name}
      </p>
      <div className="line" />
      <p className="info">{t('SERVICE_DETAILS.CONFIRMATION_SENT')}</p>
    </div>
  );
}

Success.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Success;
