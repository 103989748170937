import React from 'react';
import '../../styles/header.scss';

function Header() {
  return (
    <div className="header">
      <img className="logo" src={require('../../assets/Mioo_Black.svg')} alt="logo" />
    </div>
  );
}

export default Header;
