import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import LaddaButton, { SLIDE_DOWN } from 'react-ladda';

import Input from '../common/input';
import Translator from '../common/translator';
import config from '../../config/config';
import { scrollToFirstError, requiredField, emailField } from '../../helpers/validator';

import BookingCalendar from './booking-calendar';

let ServiceDetailsForm = ({ handleSubmit, submitting }, { t }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        type="text"
        name="first_name"
        label={t('SERVICE_DETAILS.FIRST_NAME_LABEL')}
        className="form-control"
        placeholder={t('SERVICE_DETAILS.FIRST_NAME_PLACEHOLDER')}
        component={Input}
      />
      <Field
        type="text"
        name="last_name"
        label={t('SERVICE_DETAILS.LAST_NAME_LABEL')}
        className="form-control"
        placeholder={t('SERVICE_DETAILS.LAST_NAME_PLACEHOLDER')}
        component={Input}
      />
      <Field
        type="text"
        name="email"
        label={t('SERVICE_DETAILS.EMAIL_LABEL')}
        className="form-control"
        placeholder={t('SERVICE_DETAILS.EMAIL_PLACEHOLDER')}
        component={Input}
      />
      <hr className="line" />
      <Field
        type="text"
        name="address"
        label={t('SERVICE_DETAILS.ADDRESS_LABEL')}
        className="form-control"
        placeholder={t('SERVICE_DETAILS.ADDRESS_PLACEHOLDER')}
        component={Input}
      />
      <Field
        type="text"
        name="post_code"
        className="form-control"
        placeholder={t('SERVICE_DETAILS.POST_CODE_PLACEHOLDER')}
        component={Input}
      />
      <hr className="line" />
      <Field
        type="tel"
        name="phone_number"
        label={t('SERVICE_DETAILS.PHONE_NUMBER_LABEL')}
        countryCode="+44"
        className="form-control"
        placeholder={t('SERVICE_DETAILS.PHONE_NUMBER_PLACEHOLDER')}
        component={Input}
      />
      <hr className="line" />
      <BookingCalendar />
      <div className="refund-info">{t('SERVICE_DETAILS.CONFIRMATION_EMAIL')}</div>

      <Field
        type="price"
        name="readonly"
        readOnly={true}
        amount={`£ ${config.defaultPrice}`}
        className="form-control"
        placeholder={t('SERVICE_DETAILS.PRICE_PLACEHOLDER')}
        component={Input}
      />

      <LaddaButton
        className="btn w-100 submit-button"
        disabled={submitting}
        data-style={SLIDE_DOWN}
      >
        {t('COMMON.CONTINUE')}
      </LaddaButton>
    </form>
  );
};

const serviceDetailsFormValidator = (values) => {
  const errors = {};

  requiredField(
    values,
    errors,
    'first_name',
    <Translator>{'SERVICE_DETAILS.FIRST_NAME_REQUIRED'}</Translator>
  );
  requiredField(
    values,
    errors,
    'last_name',
    <Translator>{'SERVICE_DETAILS.LAST_NAME_REQUIRED'}</Translator>
  );
  requiredField(
    values,
    errors,
    'email',
    <Translator>{'SERVICE_DETAILS.EMAIL_REQUIRED'}</Translator>
  );
  requiredField(
    values,
    errors,
    'address',
    <Translator>{'SERVICE_DETAILS.ADDRESS_REQUIRED'}</Translator>
  );
  requiredField(
    values,
    errors,
    'phone_number',
    <Translator>{'SERVICE_DETAILS.PHONE_NUMBER_REQUIRED'}</Translator>
  );
  requiredField(
    values,
    errors,
    'post_code',
    <Translator>{'SERVICE_DETAILS.POST_CODE_REQUIRED'}</Translator>
  );
  emailField(values, errors, 'email', <Translator>{'SERVICE_DETAILS.EMAIL_VALID'}</Translator>);

  return errors;
};

ServiceDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ServiceDetailsForm = reduxForm({
  form: 'service-details',
  validate: serviceDetailsFormValidator,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ServiceDetailsForm);

ServiceDetailsForm = connect((state) => ({
  initialValues: state.service.user,
}))(ServiceDetailsForm);

export default ServiceDetailsForm;
