import React from 'react';

import { CancelBooking, Checkout, ServiceDetails } from './views';
import Success from './views/success-view';

const routes = [
  {
    path: '/',
    exact: true,
    view: <ServiceDetails />,
    meta: {
      title: 'MIOO Final Assembly',
    },
  },
  {
    path: '/checkout',
    view: <Checkout />,
    meta: {
      title: 'MIOO Final Assembly',
    },
  },
  {
    path: '/success',
    view: <Success />,
    meta: {
      title: 'MIOO Final Assembly',
    },
  },
  {
    path: '/cancel-service/:token',
    view: <CancelBooking />,
    meta: {
      title: 'MIOO Final Assembly',
    },
  },
];

export default routes;
