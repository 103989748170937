import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { cancelBooking } from '../state/actions/service';

import '../styles/success.scss';

function CancelBooking(_, { t }) {
  const loading = useSelector((state) => state.service.loading);
  const error = useSelector((state) => state.service.error);
  const success = useSelector((state) => state.service.success);
  const dispatch = useDispatch();

  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(cancelBooking(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="container success-container text-center">
      {loading && (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {success && <h1>{t('SERVICE_DETAILS.BOOKING_CANCELED')}</h1>}
      {!success && error && <h1>{t('SERVICE_DETAILS.BOOKING_NOT_CANCELED')}</h1>}
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
}

CancelBooking.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CancelBooking;
