import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';

import { service } from './reducers/service';

const rootReducer = combineReducers({
  i18nState,
  form: formReducer,
  service,
});

export default rootReducer;
