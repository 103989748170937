export const ACTIONS = {
  /** SERVICE ACTIONS START */
  USER_DETAILS_SUBMIT: 'USER_DETAILS_SUBMIT',
  BOOKING_DETAILS_SUBMIT: 'BOOKING_DETAILS_SUBMIT',

  SUBMIT_PURCHASE_START: 'SUBMIT_PURCHASE_START',
  SUBMIT_PURCHASE_SUCCESS: 'SUBMIT_PURCHASE_SUCCESS',
  SUBMIT_PURCHASE_FAILURE: 'SUBMIT_PURCHASE_FAILURE',

  CANCEL_BOOKING_START: 'CANCEL_BOOKING_START',
  CANCEL_BOOKING_SUCCESS: 'CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAILURE: 'CANCEL_BOOKING_FAILURE',
  /** SERVICE ACTIONS END */
};
