// local configuration object
const local = {
  API_BASE_URL: 'http://localhost:8000/',
  STRIPE_API_KEY: 'pk_test_lSFjNP2DFfbdAkm3xwGcJdGW',
  serviceSlots: [
    { id: 1, name: '08:00 - 17:00' },
    { id: 2, name: '17:00 - 20:00' },
  ],
};

// dev environment
const development = {
  API_BASE_URL: 'https://dev-api.mioo.cc/',
  STRIPE_API_KEY: 'pk_test_TfXXWPOi4mlUGeTYgFJB13On',
  serviceSlots: [
    { id: 8, name: '08:00 - 17:00' },
    { id: 9, name: '17:00 - 20:00' },
  ],
};

// staging environment
const staging = {
  API_BASE_URL: 'https://stg-api.mioo.cc/',
  STRIPE_API_KEY: 'pk_test_TfXXWPOi4mlUGeTYgFJB13On',
};

const production = {
  API_BASE_URL: 'https://api-v2.mioo.cc/',
  STRIPE_API_KEY: 'pk_live_QyukB0r7Bu4MafwS21BHuYmc',
  serviceSlots: [
    { id: 4, name: '08:00 - 17:00' },
    { id: 5, name: '17:00 - 20:00' },
  ],
};

const config =
  process.env.REACT_APP_BUILD_TARGET === 'PRODUCTION'
    ? production
    : process.env.REACT_APP_BUILD_TARGET === 'STAGING'
    ? staging
    : process.env.REACT_APP_BUILD_TARGET === 'DEVELOPMENT'
    ? development
    : local;

export default {
  source: 'blocket-uk',
  defaultLanguage: 'en',
  bikeBuddy: {},
  defaultPrice: 49.99,
  ...config,
};
