import React from 'react';
import '../../styles/input.scss';

function Input({
  input,
  label,
  placeholder,
  disabled,
  type,
  onKeyPress,
  onKeyUp,
  readOnly,
  countryCode,
  amount,
  meta: { touched, error, invalid, warning },
}) {
  if (readOnly) input.readOnly = true;
  return (
    <>
      <label className="input-label" htmlFor={input.name}>
        {label}
      </label>
      <div className={`input-group mb-3`} style={{ marginTop: !label && -25 }}>
        {type === 'tel' && (
          <div className="input-group-prepend">
            <span className="input-group-text">{countryCode}</span>
          </div>
        )}
        <input
          {...input}
          id={input.name}
          value={input.value}
          className={`form-control ${readOnly && 'no-select'} ${
            touched && invalid ? 'is-invalid' : ''
          } `}
          placeholder={placeholder}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          type={type}
        />
        {type === 'price' && (
          <div className="input-group-append">
            <span className="input-group-text">{amount}</span>
          </div>
        )}
      </div>
    </>
  );
}

export default Input;
