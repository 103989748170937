import validator from 'validator';

// Helpers
export const propertyInObject = (obj, property) => {
  if (obj[property] || typeof obj[property] === 'boolean' || typeof obj[property] === 'number') {
    return true;
  }
  return false;
};

export const requiredField = (formValues, errorObj, field, message) => {
  // if(field === 'content_data.name') debugger
  const error = errorObj;
  if (!propertyInObject(formValues, field) || formValues[field] === '') {
    error[field] = message;
  }
  return error;
};

export const requiredFileField = (formValues, errorObj, field, message) => {
  const error = errorObj;

  if (
    !propertyInObject(formValues, field) ||
    formValues[field] === '' ||
    (formValues[field] && formValues[field].length === 0)
  ) {
    error[field] = message;
  }
  return error;
};

export const emailField = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (formValues[field] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues[field])) {
    error[field] = message;
  }
  return error;
};

export const compareField = (formValues, errorObj, field, field1, message) => {
  const error = errorObj;
  if (formValues[field] !== formValues[field1]) {
    error[field] = message;
  }
  return error;
};

export const numberField = (limit) => {
  return (value, previousValue) => {
    if (!limit) {
      return validator.isNumeric(value) || value === '' ? value : previousValue;
    }
    return (value.length <= limit && validator.isNumeric(value)) || value === ''
      ? value
      : previousValue;
  };
};

export const minValue = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (parseInt(formValues[field], 10) < parseInt(min, 10)) {
    error[field] = message;
  }
  return error;
};

export const minDecimalValue = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (parseFloat(formValues[field]) < parseFloat(min)) {
    error[field] = message;
  }
  return error;
};

export const maxValue = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (parseInt(formValues[field], 10) > parseInt(min, 10)) {
    error[field] = message;
  }
  return error;
};

export const minLength = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (formValues[field] !== undefined && formValues[field].length < min) {
    error[field] = message;
  }
  return error;
};

export const maxLength = (formValues, errorObj, field, max, message) => {
  const error = errorObj;
  if (formValues[field] !== undefined && formValues[field].length > max) {
    error[field] = message;
  }
  return error;
};

export const mustContainLowercaseLetter = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!/[a-z]/.test(formValues[field])) {
    error[field] = message;
  }
};

export const mustContainUppercaseLetter = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!/[A-Z]/.test(formValues[field])) {
    error[field] = message;
  }
};

export const mustContainNumber = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!/\d/.test(formValues[field])) {
    error[field] = message;
  }
};

export const mustContainSpecialChar = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!/[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/.test(formValues[field])) {
    error[field] = message;
  }
};

export const scrollToFirstError = (errors = {}) => {
  let inputs;

  for (let property in errors) {
    inputs = document.querySelectorAll(`[name="${property}"]`);

    if (inputs.length && inputs[0] && inputs[0].scrollIntoView) {
      inputs[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      break;
    }
  }
};
