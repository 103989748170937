import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';

import './App.scss';
import { Header } from './components/common';
import routes from './routes';

function App() {
  moment.locale('en-gb');

  return (
    <div>
      <Header />
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route key={index} path={route.path} exact={route.exact}>
                {route.view}
              </Route>
            );
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
