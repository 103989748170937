import { ACTIONS } from '../actions';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  user: {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    address: '',
    post_code: '',
  },
  booking: {
    service_slot_id: 0,
    date: new Date(),
  },
};

export const service = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.USER_DETAILS_SUBMIT:
      return {
        ...state,
        user: action.payload,
        error: null,
      };
    case ACTIONS.BOOKING_DETAILS_SUBMIT:
      return {
        ...state,
        booking: action.payload,
      };
    case ACTIONS.SUBMIT_PURCHASE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIONS.SUBMIT_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ACTIONS.SUBMIT_PURCHASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACTIONS.CANCEL_BOOKING_START:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ACTIONS.CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
