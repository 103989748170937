import React from 'react';
import ReactDOM from 'react-dom';
import I18n from 'redux-i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';

import { translations } from './translations';
import { store } from './state/store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import initilizeHttpClient from './config/axios-config';

initilizeHttpClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18n translations={translations} fallbackLang="en" initialLang="en">
        <App />
      </I18n>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
