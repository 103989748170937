import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';

import config from '../../config/config';
import '../../styles/lib/calendar.scss';
import { submitBookingInfo } from '../../state/actions/service';

function BookingCalendar(_, { t }) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 3);

  const [date, onDateChange] = useState(currentDate);
  const [selectedSlot, onSlotChange] = useState(config.serviceSlots[0]);
  const dispatch = useDispatch();

  // Lowest date to include today for calendar
  const lowestDate = new Date();
  lowestDate.setDate(lowestDate.getDate() + 2);

  useEffect(() => {
    dispatch(submitBookingInfo({ date, service_slot_id: selectedSlot.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedSlot]);

  useEffect(() => {
    date.setHours(date.getHours() + 20);
    dispatch(submitBookingInfo({ date, service_slot_id: selectedSlot.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div>
      <label class="input-label calendar-label">{t('SERVICE_DETAILS.DAY_OF_SERVICE')}</label>
      <Calendar
        defaultView="month"
        next2Label=""
        locale="en"
        showNeighboringMonth={false}
        prev2Label=""
        tileDisabled={({ date }) => date < lowestDate}
        formatShortWeekday={(_, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
        onChange={onDateChange}
        value={date}
      />
      <div className="row">
        {config.serviceSlots.map((slot) => {
          return (
            <ServiceSlot
              key={slot.id}
              slot={slot}
              isSelected={selectedSlot === slot}
              onChange={onSlotChange}
            />
          );
        })}
      </div>
      <p className="slot-tip">{t('SERVICE_DETAILS.CONTACT')}</p>
      <hr className="line" />
    </div>
  );
}

function ServiceSlot({ slot, isSelected, onChange }) {
  return (
    <div
      className={`col service-slot ${isSelected && 'service-slot-active'}`}
      onClick={() => onChange(slot)}
    >
      {slot.name}
    </div>
  );
}

BookingCalendar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BookingCalendar;
