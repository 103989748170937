import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CheckoutForm } from '../components/checkout';
import { submitPurchase } from '../state/actions/service';

import '../styles/checkout.scss';

function Checkout() {
  const service = useSelector((state) => state.service);
  const history = useHistory();
  const dispatch = useDispatch();

  const onCheckoutSubmit = (stripeToken, cardElement, stripe) => {
    dispatch(submitPurchase(service, stripeToken, cardElement, stripe, history));
  };

  if (!service.user.email) {
    history.push('/');
  }
  return (
    <div className="container">
      <CheckoutForm onCheckoutSubmit={onCheckoutSubmit} />
    </div>
  );
}

export default Checkout;
