import axios from 'axios';

import { ACTIONS } from '../actions';

export const submitUserDetails = (user) => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.USER_DETAILS_SUBMIT, payload: user });
  };
};

export const submitBookingInfo = (booking) => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.BOOKING_DETAILS_SUBMIT, payload: booking });
  };
};

export const startLoading = () => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.SUBMIT_PURCHASE_START, payload: {} });
  };
};
export const stopLoading = () => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.SUBMIT_PURCHASE_FAILURE, payload: null });
  };
};

export const submitPurchase = (service, stripeToken, stripeElement, stripe, history) => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.SUBMIT_PURCHASE_START, payload: {} });
    axios
      .post('/account/blocket-uk-register', {
        first_name: service.user.first_name,
        last_name: service.user.last_name,
        email: service.user.email,
        phone_number: service.user.phone_number,
        address: service.user.address,
        post_code: service.user.post_code,
        stripe_token: stripeToken,
        service_date: service.booking.date,
        slot_id: service.booking.service_slot_id,
      })
      .then((res) => {
        const intent = res.data.data.intent;
        if (intent.next_action && intent.next_action.type === 'use_stripe_sdk') {
          stripe
            .confirmCardPayment(intent.client_secret, {
              payment_method: {
                card: stripeElement,
              },
            })
            .then((res) => {
              if (res.error) {
                dispatch({
                  type: ACTIONS.SUBMIT_PURCHASE_FAILURE,
                  payload: res.error,
                });
              } else {
                dispatch({ type: ACTIONS.SUBMIT_PURCHASE_SUCCESS, payload: {} });
                history.push('/success');
              }
            });
        } else {
          dispatch({ type: ACTIONS.SUBMIT_PURCHASE_SUCCESS, payload: {} });
          history.push('/success');
        }
      })
      .catch((e) => {
        dispatch({
          type: ACTIONS.SUBMIT_PURCHASE_FAILURE,
          payload: e?.response?.data?.error?.cause,
        });
      });
  };
};

export const cancelBooking = (token) => {
  return async (dispatch) => {
    dispatch({ type: ACTIONS.CANCEL_BOOKING_START, payload: null });
    axios
      .post('/account/cancel-blocket-booking', {
        token: token,
      })
      .then((res) => {
        dispatch({ type: ACTIONS.CANCEL_BOOKING_SUCCESS, payload: null });
      })
      .catch((e) => {
        dispatch({
          type: ACTIONS.CANCEL_BOOKING_FAILURE,
          payload: e?.response?.data?.error?.cause,
        });
      });
  };
};
