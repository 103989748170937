import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ServiceDetailsForm } from '../components/service-details';
import { submitUserDetails } from '../state/actions/service';
import config from '../config/config';
import '../styles/service-description.scss';

function ServiceDetails(_, { t }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onFormSubmitHandler = (data) => {
    dispatch(submitUserDetails(data));
    history.push('/checkout');
  };

  return (
    <div className="container">
      <ServiceDescription />
      <ServiceDetailsForm onSubmit={onFormSubmitHandler} />

      <div className="privacy-policy">
        <p>
          {t('SERVICE_DETAILS.TERMS_SENTENCE')} <br />
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mioo.cc/en/terms-conditions/"
            >
              {t('SERVICE_DETAILS.TERMS_OF_USE')}
            </a>{' '}
            &{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://mioo.cc/en/privacypolicy/">
              {t('SERVICE_DETAILS.PRIVACY_POLICY')}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
}

function ServiceDescription(_, { t }) {
  return (
    <div className="service-description">
      <h1>
        {t('SERVICE_DETAILS.TITLE')}
        <br /> <span>£ {config.defaultPrice.toFixed(2)}</span>
      </h1>
      <div className="break-line" />
      <div>
        <p>{t('SERVICE_DETAILS.SUBTITLE')}</p>
        <ul>
          <li>- {t('SERVICE_DETAILS.FIRST_BENEFIT')}</li>
          <li>- {t('SERVICE_DETAILS.SECOND_BENEFIT')}</li>
          <li>- {t('SERVICE_DETAILS.THIRD_BENEFIT')}</li>
          <li>- {t('SERVICE_DETAILS.FOURTH_BENEFIT')}</li>
          <li>- {t('SERVICE_DETAILS.FIFTH_BENEFIT')}</li>
        </ul>
        <p className="underline-text">{t('SERVICE_DETAILS.ONLY_APPLIES')}</p>
      </div>
    </div>
  );
}

ServiceDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

ServiceDescription.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ServiceDetails;
