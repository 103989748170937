import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import LaddaButton from 'react-ladda/dist/LaddaButton';
import { SLIDE_DOWN } from 'react-ladda/dist/constants';
import { connect, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Input from '../common/input';
import config from '../../config/config';
import { startLoading, stopLoading } from '../../state/actions/service';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '##3e515b',
      '::placeholder': {
        color: '#AAB7C4',
      },
    },
    invalid: {
      color: '#FF5454',
      iconColor: '#FF5454',
    },
  },
};

let CardInput = (
  { stripe, elements, clientSecret, onCheckoutSubmit, loading, serverError },
  { t }
) => {
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    setError('');
    dispatch(startLoading());
    // Block native form submission.
    event.preventDefault();

    // Get stripe element
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);

    // Get stripe token
    const card = await stripe.createToken(cardElement);
    if (card.error) {
      setError(card.error.message);
      dispatch(stopLoading());
    } else {
      onCheckoutSubmit(card.token.id, cardElement, stripe);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <label className="card-form-title">{t('SERVICE_DETAILS.PAY_BY_CARD')}</label>
      <ReadOnlyEmailField />
      <label className="input-label">{t('SERVICE_DETAILS.CARD_DETAILS')}</label>
      <CardNumberElement options={CARD_OPTIONS} />
      <div className="row">
        <div className="col pr-0">
          <CardExpiryElement className="border-top-none" options={CARD_OPTIONS} />
        </div>
        <div className="col pl-0">
          <CardCvcElement className="border-top-none" options={CARD_OPTIONS} />
        </div>
      </div>

      <LaddaButton
        type="submit"
        className="btn submit-button w-100 btn-lg mt-4 mb-4"
        loading={loading}
        data-style={SLIDE_DOWN}
      >
        {!loading && `${t('SERVICE_DETAILS.PAY')} £${config.defaultPrice.toFixed(2)}`}
      </LaddaButton>
      <div className="w-100 text-center">
        <p className="text-danger">{error}</p>
        <p className="text-danger">
          {serverError && serverError?.charAt(0).toUpperCase() + serverError.slice(1)}
        </p>
        <img className="w-100" alt="stripe-img" src={require('../../assets/stripe.png')} />
      </div>
    </form>
  );
};

let ReadOnlyEmailField = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-2" />
      <Field
        type="tel"
        name="email"
        readOnly={true}
        label=""
        countryCode="Email"
        className="form-control"
        placeholder="hello@mioo.cc"
        component={Input}
      />
    </form>
  );
};

CardInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

ReadOnlyEmailField = reduxForm({
  form: 'readonly-email',
})(ReadOnlyEmailField);

ReadOnlyEmailField = connect((state) => ({
  initialValues: state.service.user,
}))(ReadOnlyEmailField);

export default CardInput;
