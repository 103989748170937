import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

import config from '../../config/config';

import CardInput from './card-input';

// Load stripe
const stripePromise = loadStripe(config.STRIPE_API_KEY);

const CheckoutForm = ({ onCheckoutSubmit }) => {
  const loading = useSelector((state) => state.service.loading);
  const serverError = useSelector((state) => state.service.error);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => {
          return (
            <CardInput
              // clientSecret={clientSecret}
              elements={elements}
              stripe={stripe}
              onCheckoutSubmit={onCheckoutSubmit}
              loading={loading}
              serverError={serverError}
            />
          );
        }}
      </ElementsConsumer>
    </Elements>
  );
};

export default CheckoutForm;
